// Import image assets

// Testimonials images
import dyanne from "./img/Testimonials/dyanne_christensen.jpg";
import ishmael from "./img/Testimonials/ishmael_philemon_ackon.jpg";
import paul from "./img/Testimonials/paul_gresham.jpg";
import patricia from "./img/Testimonials/patricia_lee.jpg";
import brad from "./img/Testimonials/brad_emery.jpg";
import stephen from "./img/Testimonials/stephen_barnes.jpg";
import maria from "./img/Testimonials/maria_del_rosario.jpg";
import mark from "./img/Testimonials/mark_hiriart.jpg";
import yuly from "./img/Testimonials/yuly_rios.jpg";
import rebecca from "./img/Testimonials/rebecca_sowden.jpg";
import celia from "./img/Testimonials/celia_berkouk_schlesier.jpg";

// Images of team members
// import josephine from "./img/Team/JL.190409.COH.WEBW.Josephine.jpg";
import josephine from "./img/Team/Josephine.png";
// import kellyY from "./img/Team/JL.190409.COH.WEBW.KellyYu.jpg";
import kellyY from "./img/Team/Kelly.png";
import waiyee from "./img/Team/Waiyee.png";
import elena from "./img/Team/Elena.png";
import keung from "./img/Team/Keung.png";
import lee from "./img/Team/Lee.png";
import millie from "./img/Team/Millie.png";
import sally from "./img/Team/Sally.png";
import candy from "./img/Team/Candy.png";
import jack from "./img/Team/jack.png"
// import ekaterina from "./img/Team/JL.190409.COH.WEBW.Skey.jpg";
// import martine from "./img/Team/JL.190409.COH.WEBW.Martin.jpg";
import billa from "./img/Team/Billa.png";
// import jessica from "./img/Team/JL.190409.COH.WEBW.JessicaChung.jpg";
// import chinenye from "./img/Team/JL.190409.COH.WEBW.ChinenyeEgbuna.jpg";

export const testimonial = [
    {
        name: "Dyanne Christensen",
        position: "Elite Pacific Properties",
        testimonial:
            "Josephine is very hands on, aims to please and very easy to work with great results.",
        img: dyanne
    },
    {
        name: "Ishmael Philemon Ackon",
        position: "P&D MPC HK Co. LTD",
        testimonial:
            "Guys, if you have a Hong Kong registered business i will suggest you use Center O Hong Kong as you secretary, I used about two different companies in Hong Kong and they almost got me into trouble with the tax department. Josephine stepped in and go me back on track with some wonderful recommendations. Center O is a one stop solution.. I strongly recommend them to you. Their staff are very warm to clients ... Cheers and good Luck!! ",
        img: ishmael
    },
    {
        name: "Paul Gresham",
        position: "Credit Suisse",
        testimonial:
            "It's always great to know and be around highly self-motivated individuals, especially when they hold high standards of integrity and are as professional and dedicated as Josephine. For someone to rely on and trust to get things done, rely on Josephine and her team.",
        img: paul
    },
    {
        name: "Patricia Lee",
        position: "Solicitor of the Supreme Court",
        testimonial:
            "Over the past few months, I have experienced an outstanding level of services provided by Centre O. Centre O is in a convenient, culture-rich setting that makes this business centre the ideal choice as the launch pad for any business venture.",
        img: patricia
    },
    {
        name: "Brad Emery",
        position: "The Aimviva Travel Club",
        testimonial:
            "Josephine helped me in 2 start up businesses and proved to be hard working and diligent as well as creative in both situations. Josephine has an extensive network within Hong Kong and has been able to solve both trivial and major business problems for me as a result. Whether you are looking for office space, Visa advice, a registered Notary, or a logo for your business cards Josephine can help.",
        img: brad
    },
    {
        name: "Stephen Barnes",
        position: "Hong Kong Visa Centre",
        testimonial:
            "...We partnered with Josephine and her team at Centre O. The combination of Centre O's virtual office service and on-demand conference room facility has been the perfect antidote to the outrageously prohibitive cost of business accommodation in Hong Kong. Jo and her team go out of their way to help, are consummately professional and have enabled our business to have a bricks and mortar foundation with none of the expense. Highly recommended!",
        img: stephen
    },
    {
        name: "Maria Gallardo",
        position: "What the frock?! Limited",
        testimonial:
            "Josephine has always been extremely professional and accommodating to my business needs. On top of that, she goes the extra mile to make introductions that might help grown her clients businesses. She is very well organised and efficient, and takes care of all my business accounting so I don't need to worry about it and I can actually focus on growing the business. She has always been a great colleague to work with and I can not recommend her services more.",
        img: maria
    },
    {
        name: "Mark Hiriart",
        position: "Flow Derivative",
        testimonial:
            "I cannot recommend Josephine enough… She always goes the extra mile to help and is super responsive to questions. She has a very vast network to tap into and can help out on pretty much anything. Most of the extra value you get from using her business can't actually be put down on paper as she goes way beyond what you actually pay for.",
        img: mark
    },
    {
        name: "Yuly Rios",
        position: "Strawberrynet",
        testimonial:
            "It was a real pleasure working with Josephine's CentreO, it is a great company with staff as efficient and friendly as it gets. We found all the solutions we were looking for, and more, at incredible rates. I would recommend Josephine and her team, without hesitating, they are a one stop solution for any business, new or not, in Hong Kong!",
        img: yuly
    },
    {
        name: "Rebecca Sowden",
        position: "Cathay Pacific/HSBC HK Sevens",
        testimonial:
            "Josephine and the team at Centre o made it easy to get everything I needed to get my HK business set up, offering a seamless one-stop service! Always quick and friendly to respond, it was easy to get all the information I needed and made the experience pleasant. Thanks for all your help!",
        img: rebecca
    },
    {
        name: "Celia Berkouk-Schlesier",
        position: "Just Campagne",
        testimonial:
            "It’s my absolute pleasure to recommend Josephine! Her knowledge and expertise of HK company incorporation and bank account opening was a huge advantage to our entity. Without a doubt, I confidently recommend Josephine and her team !",
        img: celia
    }
];

export const teamMembers = [
    {
        name: "Josephine Lau",
        image: josephine,
        position: "CEO/Founder",
        description:
            "Josephine has always been an entrepreneur, with a deep understanding of how difficult is it to focus on growing a business without getting distracted by unrelated tasks, she is missioned to offer business owners a peace of mind. Since starting Centre O in 2010, she continues to enjoy helping clients with their challenges. Her ultimate goal is to help  clients to focus and their sales and building up their brand by creating more time for them.",
        socialLink: {
            li: "https://www.linkedin.com/in/hkbusinessresourcecentre/",
            md: "https://www.medium.com/@josephine_97532"
        }
    },
    {
        name: "Kelly Yu",
        image: kellyY,
        position: "Business Development Manager",
        description:
            "Centre O provides all-round services to the entrepreneurs to simplify their workload and they can be more focus on their own business.  This is her goal to put it into practice, from company set up, bank account introduce to accounting and auditing. Client's satisfaction is her motivation.",
        socialLink: {
            li: "",
            md: ""
        }
    },
    // {
    //     name: "Kelly Heung",
    //     image: kellyH,
    //     position: "Accounting and Auditing Manager",
    //     description:
    //         "As a Company Secretary, Kelly is responsible for helping clients with every stage of their business from the company incorporation, onboarding new Directors and Shareholders, conducting yearly filing and renewing Business Registration, to relocating companies. As an Office Manager, she is just like a 'Mom' to our beautiful Wanchai office with Kelly and Josephine.",
    //     socialLink: {
    //         li: "",
    //         md: ""
    //     }
    // },
    // {
    //     name: "Ekaterina Stefashina",
    //     image: ekaterina,
    //     position: "Office Manager",
    //     description:
    //         "As a Company Secretary, Kelly is responsible for helping clients with every stage of their business from the company incorporation, onboarding new Directors and Shareholders, conducting yearly filing and renewing Business Registration, to relocating companies. As an Office Manager, she is just like a 'Mom' to our beautiful Wanchai office with Kelly and Josephine.",
    //     socialLink: {
    //         li: "",
    //         md: ""
    //     }
    // },
    // {
    //     name: "Martine Shum",
    //     image: martine,
    //     position: "Office Superhero",
    //     description:
    //         "As an administrative officer in Centre O, his goal is to maintain an efficient workflow in the office by completing clerical tasks, which enable colleagues react to client’s enquiries precisely. For example, sorting and filing client’s company related documents, assist in contract drafting, call forwarding, appointment scheduling, issue invoices and office supplies management. ",
    //     socialLink: {
    //         li: "",
    //         md: ""
    //     }
    // },
    {
        name: "Billa Mok",
        image: billa,
        position: "Business Plan Specialist",
        description:
            "Working as a Business Plan Specialist, Billa’s responsibility is to create comprehensive business plan for clients. This broadens her horizon to go in-depth to difference industries. Billa is also a wizard of the accounting system -  XERO. ",
        socialLink: {
            li: "",
            md: ""
        }
    },
    {
        name: "Waiyee",
        image: waiyee,
        position:"Office Superhero",
        description:"Waiyee is one of the Superhero at the Dongguan office, providing administrative and accounting support to clients. Her main responsibilities are monthly accounting and annual general ledger entry, tracking customer orders, and timely entry of bank information. Waiyee is also a wizard of the could accounting system - XERO.",
        socialLink:{
            li:"",
            md:""
        }
    },
    {
        name: "Sally",
        image: sally,
        position:"Office Superhero",
        description:"Sally is the first Office Superhero in Dongguan office. She provides accounting and administrative support to clients and interned office, also one of her strong working skills are to maintain and upkeep client relationship and smooth communications.",
        socialLink:{
            li:"",
            md:""
        }
    },
    {
        name: "Keung",
        image: keung,
        position:"Office Superhero",
        description:"Keung , the only male Office Superhero at Dongguan office, managing daily correspondence, filing and filing, service updates and monthly accounting. He is also responsible for purchasing materials and is in-charged of managing our   flurry colleagues in China.",
        socialLink:{
            li:"",
            md:""
        }
    },
    {
        name: "Jack",
        image: jack,
        position:"Company Secretary",
        description:"As a Company Secretary, Jack is responsible for helping clients with every stage of their business from the company incorporation, on boarding new Directors and Shareholders, conducting yearly filling and renewing Business Registration, to relocating companies.",
        socialLink:{
            li:"",
            md:""
        }
    },
    {
        name: "Elena",
        image: elena,
        position:"Office Superhero",
        description:"Elena is located at Hong Kong office as an Office Superhero. Manages the daily letters, documents sorting and filing, services renewal and walk-in enquires. Everyday, she greets client with a profession smile and help with all nitty gritty administrative work for clients.",
        socialLink:{
            li:"",
            md:""
        }
    },
    {
        name: "Millie",
        image: millie,
        position:"Office Superhero",
        description:"Liz is our newly hired Superhero at the Dongguan office. She’s a fast learner pickling up customers monthly accounting and administrative work. Her short term goal is to be one certified Xero advisor within next 6 months.",
        socialLink:{
            li:"",
            md:""
        }
    },
    {
        name: "Candy",
        image: candy,
        position:"Communication Speciacist",
        description:"Candy is our newly hired markting assistant at the Dongguan office.In charge of daily push of articles on the office official account and website, she is also responsible for understanding customer needs, improving and expanding the company's customer database, and keeping communication with old customers.",
        socialLink:{
            li:"",
            md:""
        }
    }
    // {
    //     name: "Chinenye Egbuna",
    //     image: chinenye,
    //     position: "Assistant Account Manager",
    //     description:
    //         "Chinenye is the accounting and administrative assistant at Centre O. She provides administrative and accounting support to clients and colleagues. Her main responsibility is to help clients automate their accounting procedures using cloud-based software.",
    //     socialLink: {
    //         li: "",
    //         md: ""
    //     }
    // },
    // {
    //     name: "Jessica Chung",
    //     image: jessica,
    //     position: "Office Superhero",
    //     description:
    //         "Jessica is the office superhero to manage the daily letters, documents sorting and filing, services renewal and monthly accounting work. She knows about the dates and numbers, to ensure clients renew services on time, maintaining a good relationship and smooth communication with our clients.",
    //     socialLink: {
    //         li: "",
    //         md: ""
    //     }
    // }
]